import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ListItem, NumberedList, Link, UnorderedList, StrongText } from '@entur/typography';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const BannerAlertBox = makeShortcode("BannerAlertBox");
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "reserve-offer-api"
    }}>{`Reserve Offer API`}</h1>
    <p>{`Use this api to easily reserve an offer. This api supports multiple use cases.`}</p>
    <BannerAlertBox variant="info" mdxType="BannerAlertBox">
If performing multiple changes in a row on the same order lines before payment/confirmation, consistency cannot be guaranteed in case of a failure. Use with caution.
It might be necessary to reset the order and start the whole process anew.
    </BannerAlertBox>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <NumberedList className="reserve-offers-toc" mdxType="NumberedList">
    <ListItem mdxType="ListItem"> <Link href="#add-order-lines-to-order" mdxType="Link">Add order lines to order</Link></ListItem>
    <ListItem mdxType="ListItem"> <Link href="#update-order-lines-in-order" mdxType="Link">Replace order lines in order</Link></ListItem>
    <ListItem mdxType="ListItem"> <Link href="#adding-supplement-products" mdxType="Link">Adding supplement products</Link></ListItem>
    <ListItem mdxType="ListItem"> <Link href="#change-options" mdxType="Link">Change options</Link></ListItem>
    <ListItem mdxType="ListItem"> <Link href="#api-reference" mdxType="Link">Api reference</Link></ListItem>
    </NumberedList>
    <h2 {...{
      "id": "add-order-lines-to-order"
    }}>{`Add order lines to order`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Add reservable offers to an order using the `}<a parentName="p" {...{
        "href": "#api-reference"
      }}>{`/v1/reserve-offers api`}</a>{`.
It accepts a set of offerIds which might need reservations, performs reservations where applicable, creates order lines from the offers, and adds them to an order.
A new order is created if no `}<inlineCode parentName="p">{`orderId`}</inlineCode>{` is supplied.`}</p>
    <p>{`Changes to order lines has been deprecated from this api, please use the `}<a parentName="p" {...{
        "href": "#update-order-lines-in-order"
      }}>{`Update Order api`}</a></p>
    <h3 {...{
      "id": "special-properties"
    }}>{`Special properties`}</h3>
    <p><inlineCode parentName="p">{`selectedTravellerIds`}</inlineCode>{` is used to specify which travellers will be included in the use of this offer.
`}<inlineCode parentName="p">{`selectedTravellerIds`}</inlineCode>{` is validated against the travellers identified in the travellerMappings property of the selected offer, as well as the legal number of user profiles per compartment when reserving a group product.
Only one order line is created when reserving a group product. When reserving regular products, the number of order lines made corresponds to the number of selectedTravellerIds. Each order line will have one of the selectedTravellerIds.
When `}<inlineCode parentName="p">{`selectedTravellerIds`}</inlineCode>{` is not set, it is assumed that all travellers in the TravellerMapping should be added for the offer.
`}<inlineCode parentName="p">{`selectedTravellerIds`}</inlineCode>{` `}<em parentName="p">{`cannot`}</em>{` be used in conjunction with `}<inlineCode parentName="p">{`count`}</inlineCode>{`, and any attempt to do so will be rejected by the api.`}</p>
    <h2 {...{
      "id": "update-order-lines-in-order"
    }}>{`Update order lines in order`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <BannerAlertBox variant="info" mdxType="BannerAlertBox">
Using this endpoint will completely override the previous version of the order line. This will also include any seat reservations.
It does not support performing seat upgrades on an existing order, as the base fare product will be completely removed.
    </BannerAlertBox>
    <p>{`To update an order line, use the `}<a parentName="p" {...{
        "href": "#api-reference"
      }}>{`/v1/update-order api`}</a>{`.
Valid change options are accessible through the `}<a parentName="p" {...{
        "href": "#change-options"
      }}>{`change options api`}</a>{`.`}</p>
    <p>{`Change options and fees are calculated compared to the last `}<inlineCode parentName="p">{`CONFIRMED`}</inlineCode>{` version of each order line.
Successive updates after replacing order lines should be done with great care. The original order line ids should then be used for best results.`}</p>
    <h3 {...{
      "id": "changing-the-number-of-order-lines"
    }}>{`Changing the number of order lines`}</h3>
    <p>{`It is possible to replace order lines by changing from a group compartment products to single person products or the inverse.
In this case, the previous order line(s) will be marked as `}<inlineCode parentName="p">{`REPLACED`}</inlineCode>{`, and new order line(s) are created. The new order line ids are returned to the client.`}</p>
    <p>{`There are two mechanisms available to change one order line into multiple.
If the order line can be replaced by a single offer, set the property `}<a parentName="p" {...{
        "href": "#special-properties"
      }}><inlineCode parentName="a">{`selectedTravellerIds`}</inlineCode></a>{` to specify which travellers are using this offer. One order line will be created for each traveller.
In the case of replacing one order line with multiple offers, supply multiple offer configurations with the same order line id. Property `}<inlineCode parentName="p">{`selectedOrderLineIds`}</inlineCode>{` must be equal.`}</p>
    <p>{`To change from multiple order lines to one, set the property `}<inlineCode parentName="p">{`selectedOrderLineIds`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "change-fees"
    }}>{`Change fees`}</h3>
    <p>{`Fees of type `}<em parentName="p">{`CHANGE`}</em>{` may be added to the order depending on what is configured with the relevant products and their elements.
In the case of a negative price difference (e.g. the new order line(s) are cheaper), a special fee of type `}<em parentName="p">{`CHANGE_NON_REFUNDABLE`}</em>{` is added for changes concerning non-refundable products.`}</p>
    <h2 {...{
      "id": "adding-supplement-products"
    }}>{`Adding supplement products`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Add one or more supplement products to an order line with the endpoint `}<a parentName="p" {...{
        "href": "#api-reference"
      }}><inlineCode parentName="a">{`/v1/update-order/supplement-products`}</inlineCode></a>{`.
Use the property `}<inlineCode parentName="p">{`selectedProductsConfiguration`}</inlineCode>{` to specify selectableId and count.
The provided offer must be for supplement products only, `}<a parentName="p" {...{
        "href": "/pages-offers-docs-guides-v1#supplement-products"
      }}>{`as per the Offers guide`}</a></p>
    <p><strong parentName="p">{`!Note`}</strong>{`
Only supplement products which does not modify the existing seat reservations may be added at this time. In practice, this is mostly Bikes and other related luggage products.`}</p>
    <h2 {...{
      "id": "change-options"
    }}>{`Change Options`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`A change option is a specification for a legal change that may be performed on an order. It can either be free, or come with a fee that needs to be paid.
The client is responsible for selecting which changes to perform on the order.`}</p>
    <BannerAlertBox variant="warning" mdxType="BannerAlertBox">
    <div>
        After a change, the old tickets is no longer valid and should therefore not be accessible anymore. Remember to always redistribute and update tickets after a change. A failure to do so may lead to
        <UnorderedList mdxType="UnorderedList">
            <ListItem mdxType="ListItem">Invalid tickets</ListItem>
            <ListItem mdxType="ListItem">Tickets with incorrect information (seats, dates etc.)</ListItem>
            <ListItem mdxType="ListItem">Misuse of tickets that have actually been cancelled</ListItem>
        </UnorderedList>
    </div>
    </BannerAlertBox>
    <h3 {...{
      "id": "get-possible-change-options"
    }}>{`Get possible change options`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "#api-reference"
      }}>{`Change-options endpoint`}</a>{` finds and returns possible change options for an order.`}</p>
    <h3 {...{
      "id": "changes-to-product-or-departure-time"
    }}>{`Changes to product or departure time`}</h3>
    <p>{`Using the `}<a parentName="p" {...{
        "href": "#update-order-lines-in-order"
      }}>{`update-order endpoint`}</a>{`, it is possible to change both the product and the departure time of an order line by specifying the order line id in addition to the offer id.
If the status of the order was confirmed before updating an order line, the status will be set to draft. To confirm the order, one of the following action must be performed:`}</p>
    <ul>
      <li parentName="ul">{`If the new price is higher, the customer has to pay the difference.`}</li>
      <li parentName="ul">{`If the new price is lower and the original product is refundable, the difference is credited to the customer.`}</li>
      <li parentName="ul">{`If the new price is lower and the original product is non-refundable, a fee is added to the order line, and the order must be manually confirmed`}</li>
      <li parentName="ul">{`If there are no price changes, the order must be manually confirmed`}</li>
    </ul>
    <h3 {...{
      "id": "change-seat"
    }}>{`Change seat`}</h3>
    <p>{`To change a seat without changing product or departure time the `}<a parentName="p" {...{
        "href": "/pages-seatmap-api"
      }}>{`seat map`}</a>{` should be used. Changing seats will cancel distributed tickets and create new tickets with the correct seating information.
Changing seats should only be done as the last step, as any call to `}<inlineCode parentName="p">{`/reserve-offers`}</inlineCode>{` or `}<inlineCode parentName="p">{`/update-order`}</inlineCode>{` will override any selected seats for the included order line(s).`}</p>
    <BannerAlertBox variant="warning" mdxType="BannerAlertBox">
    <div>
        When changing seats, the client <StrongText mdxType="StrongText">must</StrongText> either edit the already existing reservation (recommended), or manually cancel the existing reservation and replace it with a new one.
        Failure to do so will lead to the train running with empty seats.
    </div>
    </BannerAlertBox>
    <h3 {...{
      "id": "add-travellers"
    }}>{`Add travellers`}</h3>
    <p>{`Adding travellers is done through Reserve-offers the same way as the initial `}<a parentName="p" {...{
        "href": "#add-order-lines-to-order"
      }}>{`reserving`}</a>{`.`}</p>
    <h3 {...{
      "id": "remove-travellers"
    }}>{`Remove travellers`}</h3>
    <p>{`It is possible to remove travellers in two different ways, depending on whether the order line is refundable or not.
To check if an order line is refundable you can use the refund-options-endpoint in the `}<a parentName="p" {...{
        "href": "/pages-sales-refund-api"
      }}>{`refund-api`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`If there are any refunding options, you can use the refund-api to perform a refund based on the refund-option that was found. Details about the refunding process is found `}<a parentName="li" {...{
          "href": "/pages-sales-guides#refunding"
        }}>{`here`}</a>{`, and the refunding-api `}<a parentName="li" {...{
          "href": "/pages-sales-refund-api"
        }}>{`here`}</a>{`.`}</li>
      <li parentName="ul">{`If there are no refund-options, it is possible to cancel the order line manually by using the `}<a parentName="li" {...{
          "href": "/pages-sales-order-api"
        }}>{`order-api`}</a>{`. In this case a manual fee equal to 100% of the price of the cancelled order line has to be applied to the order. Use the `}<a parentName="li" {...{
          "href": "/pages-sales-order-api"
        }}>{`order-api`}</a>{` to apply fees.`}</li>
    </ul>
    <h3 {...{
      "id": "after-change"
    }}>{`After change`}</h3>
    <p>{`As an order line is one to one with a ticket-distribution-group, a change in the order line will also trigger a change in the corresponding ticket-distribution-group.
When the order is completed after a change, the ticket-distributions representing the old order line will be cancelled automatically and a new one will be created based on changes.`}</p>
    <p>{`This means that these will need to be `}<a parentName="p" {...{
        "href": "/pages-sales-guides#generate-and-distribute-ticket-distributions"
      }}>{`distributed`}</a>{`
through the `}<a parentName="p" {...{
        "href": "/pages-sales-ticket-distribution-api#ticket-distribution-api-reference"
      }}>{`ticket distribution api`}</a>{`, as if this was a regular new sale.`}</p>
    <h2 {...{
      "id": "api-reference"
    }}>{`API reference`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://petstore.swagger.io/?url=https://api.entur.io/api-docs/reserve-offer"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <Swagger url="https://api.entur.io/api-docs/reserve-offer" baseUrl="https://api.entur.io/sales" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      